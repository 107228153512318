import * as React from "react";
import * as xlsx from "xlsx";
import { Publish } from "@material-ui/icons";
import { Autocomplete } from "@mui/material";
import TooltipComponent from "../../../../components/tooltip";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  ButtonGroup,
  Typography,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import DateFnsUtils from "@date-io/date-fns";
import { format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import api from "../../../../api";
import { Alert } from "@mui/lab";
import { getAuthenticatedClient } from "../../../../api/microsoftGraphApi";
import DataTable from "../../../../components/dataTable";
import { useStore } from "../../../../stores/rootStore";

class PtBrLocalizedUtils extends DateFnsUtils {
  public getCalendarHeaderText(date) {
    return format(date, "LLLL / yyyy", { locale: this.locale });
  }

  public getDatePickerHeaderText(date) {
    return format(date, "dd/MM/yyyy", { locale: this.locale });
  }
}

interface Atividade {
  id: number;
  empreendimento: string;
  atividade: string;
  funcao: string;
  quantidade: number;
  // produtividade: number;
  data_inicio_lb: any;
  data_fim_lb: any;
  status: string;
}

const filter = createFilterOptions();

export default function FormDialog() {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [atividadesData, setAtividadesData] = React.useState<Atividade[]>([]);
  const [open, setOpen] = React.useState(false);
  const [previousCounterValue, setPreviousCounterValue] =
    React.useState<number>(atividadesData.length);
  const [counter, setCounter] = React.useState<number>(atividadesData.length);
  const [currentAtividade, setCurrentAtividade] = React.useState<Atividade>({
    id: 0,
    empreendimento: "",
    atividade: "",
    funcao: "",
    quantidade: 0,
    // produtividade: 0,
    data_inicio_lb: "01/01/2000",
    data_fim_lb: "01/01/2050",
    status: "Ativa",
  });
  const [lists, setLists] = React.useState<any>({
    empreendimentos: [],
    atividades: [],
    funcoes: [],
  });
  // const [empreendimentosSharepoint, setEmpreendimentosSharepoint] =
  //   React.useState<any[]>([]);
  const { userStore } = useStore();

  React.useEffect(() => {
    (async () => {
      const { data: atividades } = await api.get<any>(
        "/atividades-medio-prazo?_limit=9999999"
      );
      const tempListState = {
        empreendimentos: [
          ...new Set(atividades.map((item) => item.empreendimento)),
        ],
        atividades: [...new Set(atividades.map((item) => item.atividade))],
        funcoes: [...new Set(atividades.map((item) => item.funcao))],
      };
      setLists({ ...tempListState });
    })();
    // getEmpreendimentosFromSharepoint();
  }, []);

  React.useEffect(() => {
    console.log(`atividadesData: ${JSON.stringify(atividadesData)}`);
  }, [atividadesData]);

  React.useEffect(() => {
    if (counter === previousCounterValue) {
      return;
    }

    if (
      counter === 1 &&
      previousCounterValue === 0 &&
      (atividadesData[0] !== null || atividadesData[0].empreendimento === "")
    ) {
      setAtividadesData([
        {
          id: 1,
          empreendimento: "",
          atividade: "",
          funcao: "",
          quantidade: 100,
          // produtividade: 0,
          data_inicio_lb: "01/01/2000",
          data_fim_lb: "01/01/2050",
          status: "Ativa",
        },
      ]);
      setPreviousCounterValue(1);
      return;
    }

    if (counter > previousCounterValue && previousCounterValue === 0) return;

    if (counter > previousCounterValue) {
      const temp = [...atividadesData];
      temp.push({
        id: atividadesData[atividadesData.length - 1].id + 1,
        empreendimento: "",
        atividade: "",
        funcao: "",
        quantidade: 0,
        // produtividade: 0,
        data_inicio_lb: "01/01/2000",
        data_fim_lb: "01/01/2050",
        status: "Ativa",
      });
      setAtividadesData([...temp]);
      setPreviousCounterValue(temp.length);
      return;
    }

    if (counter < previousCounterValue) {
      const temp = [...atividadesData];
      temp.pop();
      setAtividadesData([...temp]);
      setPreviousCounterValue(temp.length);
      return;
    }
  }, [counter]);

  const handleClickOpen = (params: any) => {
    const temp = { ...params.row };
    temp.data_inicio_lb = parse(temp.data_inicio_lb, "dd/MM/yyyy", new Date(), {
      locale: ptBR,
    });
    temp.data_fim_lb = parse(temp.data_fim_lb, "dd/MM/yyyy", new Date(), {
      locale: ptBR,
    });
    setCurrentAtividade({ ...temp });
    setOpen(true);
  };

  const client = getAuthenticatedClient(
    window.sessionStorage.getItem("access_token")
  );

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "empreendimento", headerName: "Empreendimento" },
    {
      field: "atividade",
      headerName: "Atividade",
    },
    {
      field: "funcao",
      headerName: "Função",
    },
    {
      field: "quantidade",
      headerName: "Quantidade",
    },
    // {
    //   field: "produtividade",
    //   headerName: "Produtividade",
    // },
    {
      field: "data_inicio_lb",
      headerName: "Data Inicio LB",
    },
    {
      field: "data_fim_lb",
      headerName: "Data Fim LB",
    },
    {
      field: "edit",
      headerName: "Editar",
      renderCell: (params) => (
        <>
          <Button
            style={{
              marginRight: 40,
              padding: "3px 35px",
            }}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => handleClickOpen(params)}
          >
            Editar
          </Button>
        </>
      ),
    },
  ];

  const getEmpreendimentosFromSharepoint = async () => {
    /**VALIDAÇÃO DE NOME EMPREENDIMENTO NO PLC
     * Lista "Obras" dentro do site "DADOS"
     */
    const client = getAuthenticatedClient(
      window.sessionStorage.getItem("access_token")
    );
    const dataemp = await client
      .api(
        `/sites/opusic.sharepoint.com,a776cff2-740b-45e9-8fa0-46cdc5f79d04,81b41a86-1a9d-44ea-bf2d-1cfd7d39dbc8/lists/03160df1-c45a-4263-9173-4982a1a828f6/items?expand=fields`
      )
      .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
      .get();

    const empreendimentos = dataemp.value.map((item: any) => item.fields.Title);
    setLists((prevLists: any) => ({
      ...prevLists,
      empreendimentos,
    }));
  };

  // React.useEffect(() => {
  //   getEmpreendimentosFromSharepoint();
  // }, []);
  const readUploadFile = (e) => {
    e.preventDefault();

    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json: any = xlsx.utils.sheet_to_json(worksheet);
        const formatted = json
          .map((item) => ({
            ...item,
            ...(item["DATA FIM "] && { "DATA FIM": item["DATA FIM "] }),
          }))
          .map((item) => ({
            ...Object.fromEntries(
              Object.entries(item)
                .filter(([_, v]) => v !== 0)
                .filter(([_, v]) => v !== "")
            ),
          }))
          .filter(
            (item) =>
              Object.prototype.toString.call(item["DATA FIM"]) ===
              "[object Date]"
          )
          .map((i, index) => {
            let final = [];
            const standard = {
              empreendimento: i.OBRA,
              atividade: i.ATIVIDADE,
              data_inicio_lb: i["DATA INÍCIO"]
                ? format(i["DATA INÍCIO"], "dd/MM/yyyy", {
                    locale: ptBR,
                  })
                : "",
              data_fim_lb: i["DATA FIM"]
                ? format(i["DATA FIM"], "dd/MM/yyyy", {
                    locale: ptBR,
                  })
                : "",
              funcao: "",
              quantidade: 0,
            };
            delete i.OBRA;
            delete i.ATIVIDADE;
            delete i["DATA INÍCIO"];
            delete i["DATA FIM"];
            delete i["DATA FIM "];
            if (Object.keys(i).length > 0) {
              Object.keys(i).forEach((key) => {
                final = [
                  ...final,
                  { ...standard, funcao: key, quantidade: i[key] },
                ];
              });
            } else {
              final = [standard];
            }
            return final;
          });
        let flatted = formatted.flat(Infinity);

        flatted = flatted
          .filter((item) => item.funcao !== "")
          .map((item, index) => ({
            ...item,
            id: atividadesData.length + index + 1,
          }));

        /**VALIDAÇÃO DE NOME EMPREENDIMENTO NO PLC
         * Lista "Obras" dentro do site "DADOS"
         */
        // const client = getAuthenticatedClient(
        //   window.sessionStorage.getItem("access_token")
        // );
        // const dataemp = await client
        //   .api(
        //     `/sites/opusic.sharepoint.com,a776cff2-740b-45e9-8fa0-46cdc5f79d04,81b41a86-1a9d-44ea-bf2d-1cfd7d39dbc8/lists/03160df1-c45a-4263-9173-4982a1a828f6/items?expand=fields`
        //   )
        //   .header("Prefer", "HonorNonIndexedQueriesWarningMayFailRandomly")
        //   .get();

        let listEmpreendimentos: any = [
          ...new Set(flatted.map((i) => i.empreendimento)),
        ];
        // let value: any = [
        //   ...new Set(dataemp.value.map((i) => i.fields.Title)),
        // ].map((i: any) => i.toUpperCase());

        let result: any = [];

        // for (const item of listEmpreendimentos) {
        //   if (!empreendimentosSharepoint.includes(item)) {
        //     result.push(item);
        //   }
        // }
        let tempListState: any = {};
        flatted = flatted.map((i) => ({
          ...i,
          id: i.id.toString(),
          quantidade: i.quantidade.toString(),
        }));
        if (result.length > 0) {
          if (atividadesData[0].empreendimento === "") {
            setAtividadesData([
              ...flatted.filter(
                (item) => !result.includes(item.empreendimento)
              ),
            ]);
          } else {
            setAtividadesData([
              ...atividadesData,
              ...flatted.filter(
                (item) => !result.includes(item.empreendimento)
              ),
            ]);
          }
          tempListState = {
            empreendimentos: [
              ...new Set(
                flatted
                  .filter((item) => !result.includes(item.empreendimento))
                  .map((item) => item.empreendimento)
              ),
              ...lists.empreendimentos,
            ],
            atividades: [
              ...new Set(
                flatted
                  .filter((item) => !result.includes(item.empreendimento))
                  .map((item) => item.atividade)
              ),
              ...lists.atividades,
            ],
            funcoes: [
              ...new Set(
                flatted
                  .filter((item) => !result.includes(item.empreendimento))
                  .map((item) => item.funcao)
              ),
              ...lists.funcoes,
            ],
          };

          alert(
            `Não foi possivel validar os seguintes Empreendimentos: \n${result.join(
              ", "
            )}\n Verifique na lista de Obras na Intranet`
          );
        } else {
          setAtividadesData([...atividadesData, ...flatted]);

          tempListState = {
            empreendimentos: [
              ...new Set(flatted.map((item) => item.empreendimento)),
              ...lists.empreendimentos,
            ],
            atividades: [
              ...new Set(flatted.map((item) => item.atividade)),
              ...lists.atividades,
            ],
            funcoes: [
              ...new Set(flatted.map((item) => item.funcao)),
              ...lists.funcoes,
            ],
          };
        }

        setLists({ ...tempListState });
        setCounter(flatted.length + atividadesData.length);
        setPreviousCounterValue(flatted.length + atividadesData.length - 1);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    // setLoading(false);
  };

  const handleClose = () => {
    let temp = [...atividadesData];
    let idx = atividadesData.findIndex((i) => i.id === currentAtividade.id);
    temp[idx] = currentAtividade;
    temp[idx].data_inicio_lb = format(
      currentAtividade.data_inicio_lb,
      "dd/MM/yyyy",
      { locale: ptBR }
    );
    temp[idx].data_fim_lb = format(currentAtividade.data_fim_lb, "dd/MM/yyyy", {
      locale: ptBR,
    });
    setAtividadesData([...temp]);
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const temp = atividadesData.map((item) => ({
        empreendimento: item.empreendimento,
        atividade: item.atividade,
        funcao: item.funcao,
        quantidade: item.quantidade,
        status: item.status || "Ativa",
        // produtividade: item.produtividade,
        data_inicio_lb: parse(item.data_inicio_lb, "dd/MM/yyyy", new Date(), {
          locale: ptBR,
        }),
        data_fim_lb: parse(item.data_fim_lb, "dd/MM/yyyy", new Date(), {
          locale: ptBR,
        }),
        email: userStore.email,
        }));
      const { ok } = await api.post("/atividades-medio-prazo", temp);
      if (!ok) {
        console.log(error);
        throw new Error();
      }

      if (ok) {
        setSuccess(true);
        setError(false);
      }
      console.log(temp);
    } catch (error) {
      setError(true);
      setSuccess(false);
    }
    setLoading(false);
  };

  if (loading) {
    return <CircularProgress size={60} />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Atividade</DialogTitle>
        <DialogContent>
        <Autocomplete
            disablePortal
            value={currentAtividade.empreendimento || ""}
            options={lists.empreendimentos ? [...new Set([...lists.empreendimentos])] : []}
            getOptionLabel={(option) => (typeof option === 'string' ? option : "")}
            style={{ width: 500 }}
            onChange={(_, newValue) => {
              if (newValue !== null) {
                setCurrentAtividade((prevState) => ({
                  ...prevState,
                  empreendimento: newValue,
                }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Empreendimento"
                variant="standard"
              />
            )}
          />
          <Autocomplete
            disablePortal
            value={currentAtividade.atividade || ""}
            options={lists.atividades ? lists.atividades : []}
            getOptionLabel={(option) => (typeof option === 'string' ? option : "")}
            style={{ width: 500 }}
            onChange={(_, newValue) => {
              if (newValue !== null) {
                if (newValue.startsWith("Adicionar ")) {
                  const atividades = lists.atividades;
                  atividades.push(newValue.split('"')[1]);
                  setLists({ ...lists, atividades: [...atividades] });
                  setCurrentAtividade({
                    ...currentAtividade,
                    atividade: newValue.split('"')[1],
                  });
                } else {
                  setCurrentAtividade({
                    ...currentAtividade,
                    atividade: newValue,
                  });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
              {...params}
              fullWidth
              label="Atividade"
              variant="standard"
              />
            )}
            />

            <Autocomplete
            disablePortal
            freeSolo
            value={currentAtividade.funcao || ""}
            options={lists.funcoes ? lists.funcoes : []}
            getOptionLabel={(option) => (typeof option === 'string' ? option : "")}
            style={{ width: 500 }}
            onChange={(_, newValue) => {
              if (newValue !== null) {
              if (newValue.startsWith("Adicionar ")) {
                const funcoes = lists.funcoes;
                funcoes.push(newValue.split('"')[1]);
                setLists({ ...lists, funcoes: [...funcoes] });
                setCurrentAtividade({
                ...currentAtividade,
                funcao: newValue.split('"')[1],
                });
              } else {
                setCurrentAtividade({
                ...currentAtividade,
                funcao: newValue,
                });
              }
              }
            }}
            onInputChange={(_, newInputValue) => {
              setCurrentAtividade({
              ...currentAtividade,
              funcao: newInputValue,
              });
            }}
            renderInput={(params) => (
              <TextField
              {...params}
              fullWidth
              label="Função"
              variant="standard"
              />
            )}
            />


          {/* <Autocomplete
            value={currentAtividade.empreendimento}
            onChange={(event, newValue) => {
              if (newValue.startsWith("Adicionar ")) {
                const empreendimentos = lists.empreendimentos;
                empreendimentos.push(newValue.split('"')[1]);
                setLists({ ...lists, empreendimentos: [...empreendimentos] });
                setCurrentAtividade({
                  ...currentAtividade,
                  empreendimento: newValue.split('"')[1],
                });
              } else {
                setCurrentAtividade({
                  ...currentAtividade,
                  empreendimento: newValue,
                });
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== "") {
                filtered.push(`Adicionar "${params.inputValue}"`);
              }

              return filtered;
            }}
            id="free-solo-dialog-demo"
            options={lists.empreendimentos}
            getOptionLabel={(option: any) => {
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) => option}
            style={{ width: 500 }}
            freeSolo
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Empreendimento"
                variant="standard"
              />
            )}
          />
          <Autocomplete
            value={currentAtividade.atividade}
            onChange={(event, newValue) => {
              if (newValue.startsWith("Adicionar ")) {
                const atividades = lists.atividades;
                atividades.push(newValue.split('"')[1]);
                setLists({ ...lists, atividades: [...atividades] });
                setCurrentAtividade({
                  ...currentAtividade,
                  atividade: newValue.split('"')[1],
                });
              } else {
                setCurrentAtividade({
                  ...currentAtividade,
                  atividade: newValue,
                });
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== "") {
                filtered.push(`Adicionar "${params.inputValue}"`);
              }

              return filtered;
            }}
            id="free-solo-dialog-demo"
            options={lists.atividades}
            getOptionLabel={(option: any) => {
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) => option}
            style={{ width: 500 }}
            freeSolo
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Atividade"
                variant="standard"
              />
            )}
          />
          <Autocomplete
            value={currentAtividade.funcao}
            onChange={(event, newValue) => {
              if (newValue.startsWith("Adicionar ")) {
                const funcoes = lists.funcoes;
                funcoes.push(newValue.split('"')[1]);
                setLists({ ...lists, funcoes: [...funcoes] });
                setCurrentAtividade({
                  ...currentAtividade,
                  funcao: newValue.split('"')[1],
                });
              } else {
                setCurrentAtividade({
                  ...currentAtividade,
                  funcao: newValue,
                });
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== "") {
                filtered.push(`Adicionar "${params.inputValue}"`);
              }

              return filtered;
            }}
            id="free-solo-dialog-demo"
            options={lists.funcoes}
            getOptionLabel={(option: any) => {
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) => option}
            style={{ width: 500 }}
            freeSolo
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Função"
                variant="standard"
              />
            )}
          /> */}
          <Box sx={{ m: 1 }}>
            <ButtonGroup size="small" style={{ height: 35 }}>
              <Typography>
                <Box sx={{ m: 1 }}>Quantidade </Box>
              </Typography>
              <Button disabled>+</Button>
              {counter > 0 && (
                <Button disabled>{currentAtividade.quantidade}</Button>
              )}
              {counter > 0 && <Button disabled>-</Button>}
            </ButtonGroup>
          </Box>
          {/* <Box sx={{ m: 1 }}>
            <ButtonGroup size="small" style={{ height: 35 }}>
              <Typography>
                <Box sx={{ m: 1 }}>Produtividade </Box>
              </Typography>
              <Button
                onClick={() =>
                  setCurrentAtividade({
                    ...currentAtividade,
                    produtividade: currentAtividade.produtividade + 1,
                  })
                }
              >
                +
              </Button>
              {counter > 0 && (
                <Button disabled>{currentAtividade.produtividade}</Button>
              )}
              {counter > 0 && (
                <Button
                  onClick={() =>
                    setCurrentAtividade({
                      ...currentAtividade,
                      produtividade: currentAtividade.produtividade - 1,
                    })
                  }
                >
                  -
                </Button>
              )}
            </ButtonGroup>
          </Box> */}
          <Box sx={{ m: 1 }}>
            <DesktopDatePicker
              label="Data Início LB"
              value={currentAtividade.data_inicio_lb}
              onChange={(e) =>
                setCurrentAtividade({
                  ...currentAtividade,
                  data_inicio_lb: e,
                })
              }
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  {...params}
                  disabled
                />
              )}
            />
          </Box>
          <Box sx={{ m: 1 }}>
            <DesktopDatePicker
              label="Data Fim LB"
              value={currentAtividade.data_fim_lb}
              onChange={(e) =>
                setCurrentAtividade({
                  ...currentAtividade,
                  data_fim_lb: e,
                })
              }
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  size="small"
                  {...params}
                  disabled
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button onClick={handleClose}>Editar</Button>
        </DialogActions>
      </Dialog>
      {error && (
        <Alert severity="error">
          Houve um erro ao salvar os registros. Favor entrar em contato com o
          time de TI.
        </Alert>
      )}
      {success && (
        <Alert severity="success">Registros criados com sucesso.</Alert>
      )}
      <Box sx={{ display: "flex", marginBottom: "50px" }}>
        <ButtonGroup size="small">
          <Button onClick={() => setCounter(counter + 1)}>+</Button>
          {counter > 0 && <Button disabled>{counter}</Button>}
          {counter > 0 && (
            <Button onClick={() => setCounter(counter - 1)}>-</Button>
          )}
        </ButtonGroup>
        <input
          accept="file/*"
          id="icon-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={(e) => {
            setLoading(true);
            readUploadFile(e);
            setLoading(false);
          }}
        />
        {/*
            <TooltipComponent
              htmlText={
                <Typography component="div">
                  <Box
                    textAlign="left"
                    fontWeight="fontWeightRegular"
                    fontSize={12}
                    m={1}
                  >
                    <p>Fazer upload da planilha</p>
                  </Box>
                </Typography>
              }
            >
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload sheet"
                  component="span"
                  disabled={true} // Disable the upload button
                >
                  <Publish />
                </IconButton>
              </label>
            </TooltipComponent>
            */}
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {atividadesData.length > 0 && (
          <DataTable
            columns={columns}
            rows={atividadesData}
            initialPageSize={100}
            dense
          />
        )}
      </div>
      <ButtonGroup>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Salvar
        </Button>
      </ButtonGroup>
    </div>
  );
}
